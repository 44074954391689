import React from 'react';
import RealtimeChart from './charts/RealtimeChart';

class App extends React.Component {

    render() {

        return (
            <div className="app">
                <h2>Apex Charts Demo</h2>
                <div className="row">
                    <RealtimeChart id="realtime-p1" min={70} max={85} parameter="Temperature" />
                    <RealtimeChart id="realtime-p2" min={20} max={55} parameter="Humidity" />
                    <RealtimeChart id="realtime-p3" min={25} max={75} parameter="Pieces per minute" />
                    <RealtimeChart id="realtime-p4" min={70} max={95} parameter="Electricity" />
                    <RealtimeChart id="realtime-p5" min={40} max={60} parameter="Ventilation" />
                    <RealtimeChart id="realtime-p6" min={60} max={95} parameter="Velocity" />
                    <RealtimeChart id="realtime-p7" min={10} max={30} parameter="Pressure" />
                    <RealtimeChart id="realtime-p8" min={10} max={70} parameter="Vibration" />
                </div>
            </div>
        );
    }
}

export default App;

import React from 'react';
import Chart from 'react-apexcharts';
import ApexCharts from 'apexcharts';

const time = 5000;

class RealtimeChart extends React.Component {

    constructor(props) {

        super(props);

        this.data = this.generateMinuteWiseTimeSeries(new Date('15 Jan 2020 09:00 GMT').getTime(), 100, {
            min: this.props.min,
            max: this.props.max
        });

        this.state = {
            series: [
                {
                    name: this.props.parameter,
                    data: this.data.slice(),
                },
            ],
            options: {
                chart: {
                    id: this.props.id,
                    type: 'area',
                    height: 100,
                    toolbar: {
                        show: false
                    },
                    animations: {
                        enabled: false,
                    },
                    group: 'sensors',
                },
                colors: ['#00e396'],
                stroke: {
                    curve: 'straight',
                    width: 2,
                },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    show: false,
                },
                tooltip: {
                    x: {
                        show: false,
                        format: 'dd MMM, HH:mm:ss'
                    }
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                },
                yaxis: {
                    min: 0,
                    max: 100,
                    labels: {
                        show: false,
                    }
                }
            },
            interval: null,
        };
    }

    componentDidMount() {

        const id = setInterval(this.updateChart.bind(this), time);

        this.setState({interval: id});
    }

    componentWillUnmount() {

        clearInterval(this.state.interval);
    }

    updateChart() {

        const lastDate = this.data[this.data.length - 1][0];

        this.data.push([
            lastDate + time,
            this.getRangeRandom({
                min: this.props.min,
                max: this.props.max
            })
        ]);

        this.data.shift();

        ApexCharts.exec(this.props.id, 'updateSeries', [{
            data: this.data
        }]);
    }

    generateMinuteWiseTimeSeries(baseValue, count, range) {

        let i = 0;
        const series = [];

        while (i < count) {

            const x = baseValue, y = this.getRangeRandom(range);

            series.push([
                x,
                y
            ]);

            baseValue += time;

            i++;
        }

        return series;
    }

    getRangeRandom(range) {

        return Math.floor(Math.random() * (range.max - range.min + 1)) + range.min;
    }

    render() {

        return (
            <div>
                <Chart options={this.state.options} series={this.state.series} type="area" height={100} />
            </div>
        );
    }
}

export default RealtimeChart;
